@tailwind base;
@tailwind components;

.card-heading {
  @apply text-xl sm:text-3xl font-medium mb-4;
}

.card-subheading {
  @apply text-sm sm:text-xl font-medium;
}

.select-option {
  @apply text-xl sm:text-3xl;
}

.select-label {
  @apply text-sm sm:text-xl font-medium;
}

.form-elem-container {
  @apply w-full;
}

.form-single-container {
  @apply w-full;
}

.form-input {
  @apply w-full focus:ring-0 outline-none ring-0 border-0 border-transparent bg-none bg-transparent font-light placeholder-downriver20 px-0 py-0 focus:border-transparent text-1.5xl sm:text-3xl;
}

.form-input-single {
  @apply w-full focus:ring-0 outline-none ring-0 border-0 border-b border-enpal-dark-gray bg-none bg-transparent font-light placeholder-downriver20 px-0 py-0 focus:border-enpal-dark-gray text-lg sm:text-enpal-input text-enpal-dark-gray focus:text-enpal-dark-gray;
}

.form-input-single-mobile {
  @apply sm:w-52 w-40 focus:ring-0 outline-none ring-0 border-0 border-enpal-dark-gray bg-none bg-transparent font-light placeholder-downriver20 px-0 py-0 focus:border-enpal-dark-gray text-1.5xl sm:text-3xl text-left;
}

.form-radio {
  @apply absolute -left-6 border-enpal-dark-gray ring-1 ring-white ring-inset  text-enpal-purple shadow-none bg-transparent px-0 py-0 focus:ring-inset focus:ring-white focus:ring-1 focus:ring-offset-0;
}

.form-select {
  @apply block focus:ring-0 pl-7 sm:pl-9 outline-none ring-0 border-0 bg-transparent font-light placeholder-downriver20 px-0 py-0 text-1.5xl sm:text-3xl;
}

.center-content {
  @apply flex flex-col justify-center items-center flex-grow py-0 px-0;
}

.task-question-margin {
  margin-bottom: clamp(40px, 9vh, 80px);
}

@tailwind utilities;
:root {
  --toastify-toast-min-height: 52px !important;
}
.main-page-title {
  font-size: max(30px, 3.1vw);
  line-height: max(35px, 3.1vw);
}

.heading-border {
  padding: 18px 0 7px;
  position: relative;
}

.heading-border::before {
  content: '';
  position: absolute;
  left: 8%;
  bottom: 16px;
  width: 97%;
  height: 35%;
  transform: skew(-12deg) translateX(-8%) translateY(45%);
  background: #ffc700;
  z-index: -1;
}

@media screen and (min-width: 1500px) {
  .main-page-title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media screen and (max-width: 1430) {
  .heading-border {
    padding: 18px 0 5px;
    position: relative;
  }

  .main-page-title {
    font-size: 48px;
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}

body {
  margin: 0;
  padding: 0;
  @apply w-full h-full;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* remove white bg-img from selected checkboxes */
form input[type='radio']:checked {
  background-image: none;
}

form select {
  background-position: left 0rem center;
  background-image: url('/chevron.svg');
  background-size: inherit;
}

input[id='mobile-input']::placeholder {
  text-align: right;
}

.MobileInputWidth {
  width: 9rem;
}

@media (min-width: 640px) {
  .MobileInputWidth {
    width: 12.5rem;
  }
}

.img-widget-top-bar-w {
  width: calc(100% - 40px);
}

/* remove webkit (chrome, edge (chromium) and safari) autofill backgrounds */ /* As seen here https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */ /* And here https://selleo.com/til/posts/xhgzlfgcql-a-way-to-make-autofilled-inputs-background-transparent */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s;
  /* making sure the text color is consistent in autofill: */
  color: #262626;
  -webkit-text-fill-color: #262626;
}

.scroll-gallery {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.scroll-gallery::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .polygon-clip-130 {
    display: inline-block;
    position: relative;
    height: 150px;
    width: 130px;
    box-sizing: border-box;
    clip-path: path(
      'M0.209343 37.6544L65.2093 0.154297L130.209 37.6544V112.655L65.2093 150.154L0.209343 112.655V37.6544Z'
    );
  }
  .polygon-clip-130 img {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 146px;
    width: 126px;
    clip-path: path(
      'M0.209343 36.6544L63.2093 0.154297L126.209 36.6544V109.655L63.2093 146.154L0.209343 109.655V36.6544Z'
    );
  }
}
.feedback-overlay-container {
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.15) !important;
}

.horizontal-line {
  border: none;
  height: 2px;
  background-color: #e8eaee;
  width: 100%;
}

.toast-message {
  border-radius: 89px !important;
  outline: none;
  border: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  color: #fff !important;
}
.toast-success {
  @apply !bg-go-green;
}
.toast-info {
  @apply !bg-solar-yellow !text-dark-blue;
}
.toast-error {
  @apply !bg-error-red;
}
.Toastify__toast {
  margin-bottom: 16px !important;
  padding: 12px 16px;
  max-width: fit-content;
}

.Toastify__toast-body {
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: flex-start !important;
  flex-basis: auto !important;
  max-width: fit-content;
  @apply !font-sans !text-sm;
}

.Toastify__toast-icon {
  margin-right: 8px !important;
  width: 24px !important;
  height: 24px !important;
}
.Toastify__toast-body > div:last-child {
  text-align: center;
  display: inline !important;
  flex: initial !important;
}
.Toastify__toast-container {
  top: 2rem !important;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
}

@media only screen and (max-width: 768px) {
  .Toastify__toast-container {
    padding: 0 8px !important;
  }
}
@media only screen and (min-width: 768px) {
  .Toastify__toast-body {
    @apply !font-sans !text-base;
  }
}

.cloud {
  animation: cloudMove 2s ease-in-out alternate infinite;
}

.eye {
  animation: eyeMove 2s ease-in-out alternate infinite;
}

.zoomIn {
  animation: zoomIn 250ms ease-in-out both;
}

.zoomOut {
  animation: zoomOut 250ms ease-in-out both;
}

@keyframes zoomIn {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-30%);
  }
}

@keyframes cloudMove {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(5%);
  }
}

@keyframes eyeMove {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(10%);
  }
}

.transition-max-h {
  transition-property: max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

/** Styling of DatePicker START */
/** !=================== */
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: 0px 86px 24px 0px rgba(0, 0, 0, 0),
    0px 55px 22px 0px rgba(0, 0, 0, 0.01), 0px 31px 19px 0px rgba(0, 0, 0, 0.05),
    0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 3px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.react-datepicker__month-container {
  width: 343px !important;
}
.react-datepicker__header {
  background-color: #ebf2ff !important;
  border-bottom: 4px solid #ffc700 !important;
  border-top-left-radius: 12px !important;
  padding: 24px 0 8px 0 !important;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 12px !important;
}
.react-datepicker__current-month {
  color: var(--Text-Primary, #072543) !important;
  text-align: center !important;
  font-size: 21px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important; /* 27.3px */
  padding-bottom: 24px !important;
}

.react-datepicker__navigation {
  top: 23px !important;
}

.react-datepicker__navigation-icon {
  top: 0px !important;
}

.react-datepicker__navigation--previous {
  left: 10px !important;
}
.react-datepicker__navigation-icon--next {
  left: -10px !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #6a7c8e !important;
  border-width: 2px 2px 0 0 !important;
}
.react-datepicker__day-names {
  display: flex;
  justify-content: center;
}
.react-datepicker__day-name {
  color: var(--Text-Primary, #072543) !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 28px !important; /* 175% */
  letter-spacing: -0.16px !important;

  display: flex !important;
  width: 40px !important;
  height: 45px !important;
  padding: 12px 4px !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-datepicker__month {
  margin: 0px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important;
  padding: 12px 6px !important;
}
.react-datepicker__week {
  display: flex !important;
  justify-content: center !important;
  gap: 6px !important;
}
.react-datepicker__day {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 45px !important;
  padding: 12px 4px !important;
  margin: 0px !important;

  text-align: center !important;

  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 175% */
  letter-spacing: -0.16px !important;
}
.react-datepicker__day:hover {
  background-color: #ebf2ff !important;
}
.react-datepicker__day--selected {
  background-color: #1b50bb !important;
}
.react-datepicker__day--selected:hover {
  background-color: #1b50bb !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}
/** Styling of DatePicker END */
/** !=================== */
